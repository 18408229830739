.options {
  &-wrapper {
    display: flex;
    flex-direction: column;
  }

  &-charts-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &-skew-charts-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;
    margin: 25px 0;

    .line-chart-wrapper {
      padding: 0 !important;
    }

    @media screen and (max-width: 1300px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 900px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
