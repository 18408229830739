@import '../../Constants/Styles/colors.scss';
@import '../../Constants/Styles/constants.scss';

.sidebar {
  width: $sideBarWidth;
  height: 100%;
  position: fixed;

  a {
    text-decoration: none;
  }

  &__bottom {
    position: absolute;
    left: 0;
    bottom: 170px;
    padding: 0 32px;
    color: white;
    font-size: 14px;
    line-height: 18px;
  }

  .active-block {
    width: 210px;
    display: block;
    line-height: 47px;
    position: absolute;
    background: transparent;
    padding: 1px 0;
    transform: translateX(0);
    transition: transform linear 0.3s, opacity .5s ease;

    p {
      min-height: 47px;
      margin-bottom: 0;
    }

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      background: linear-gradient(270deg, rgba(10, 10, 30, 0) 10.42%, #197CD8 39.9%, #F6029C 73.23%, #FFF200 86.25%, #2ECD94 100%);
      height: 1px;
      width: 100%;
    }

    &:before {
      top: 0;
    }
    &:after {
      top: calc(100% + 1px);
    }

    &.fade-in {
      animation: fadein .5s;
      animation-fill-mode: forwards;
    }
    &.fade-out {
      animation: fadeout 1s;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

.module-sidebar-wrap {
  max-width: 250px;
  min-height: 48px;
  position: relative;
  background: transparent;
  padding: 1px 0;

  a {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: white;
    padding: 12px 0 14px 32px;
    cursor: pointer;
    text-decoration: none;

    img {
      width: 18px;
      display: block;
      margin-right: 15px;
    }
  }

  &.color-scheme {
    &-DARK {
      a {
        color: $white0;
        text-decoration: none;
      }
    }
    &-LIGHT {
      a {
        color: $darkgray1;
        text-decoration: none;
      }
    }
  }
  .navigation-item__sub {
    display: none;
  }

  &.active2 {
    .navigation-item__sub {
      display: block;
    }
  }

  &.withChilds {
    .navigation-item {
      padding-bottom: 12px;
      &__sub {
        position: relative;

        font-family: Montserrat;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;

        padding: 8px 0;
        padding-left: 32px;

        span {
          position: relative;
          &:after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            bottom: -6px;
            width: 100%;
            height: 1px;
            background-color: #fff;
            transform-origin: right;
            transform: scaleX(0);

            transition: transform .3s ease;
          }
        }

        &:hover {
          span {
            &:after {
              transform: scaleX(1);
              transform-origin: left;
            }
          }
        }

        &.navigation-item-active {
          span {
            &:after {
              transform: scaleX(1);
              transform-origin: left;
            }
          }
        }
        
      }

      &__arrow {
        position: absolute;
        top: 17px;
        right: 16px;
        transform: rotate(0);

        transition: transform .3s ease;

        @media (max-width: 1200px) {
          display: none;
        }
        &.active {
          transform: rotate(180deg);
        }
      }
    }
  }
}

@media (max-width: 1200px){
  .sidebar {
    width: $sideBarTabletWidth;
    .active-block {
      width: $sideBarTabletWidth;
      height: 38px;
      line-height: 38px;
      margin-bottom: 18px;

      p {
        min-height: 38px;
      }
    }
  }

  .module-sidebar-wrap {
    display: flex;
    align-items: center;

    min-height: 38px;
    height: 56px;
    max-width: $sideBarTabletWidth;
    a {
      // padding: 0 0 0 47px;
      height: 38px;
      line-height: 38px;
      span {
        display: none;
      }
    }
    &.withChilds {
      .navigation-item {
        padding-bottom: 0;
        &__sub {
          display: none;
        }
      }
    }
  }

}

@media (max-width: 768px), (max-width: 812px) and (max-height: 425px) and (orientation: landscape) {
  .sidebar {

    display: none;

    pointer-events: none;

    .module-sidebar-wrap {
      display: none;
    }

    .active-block {
      display: none;
    }
  }
}

@media (max-width: 520px), (max-width: 812px) and (orientation: landscape) {
  .sidebar {
    &__languages {
      margin-bottom: 17px;
    }
    &__bottom {
      left: 16px;
      bottom: 118px;
      padding: 0;
    }
    .Switcher {
      margin: 0;
    }
  }
}


.navigation-item-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.constructor-label-new {
  width: 27px;
  height: 14px;
  text-transform: uppercase;
  color: #fff;
  font-size: 8px;
  position: absolute;
  background-color: #F6029C;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  &.sidebar-new {
    top: 8px;
    left: 15px;
    @media (max-width: 1200px) {
      top: 13px;
      left: 30px;
    }
  }

  &.wide-label-soon {
    width: 32px;
    white-space: nowrap;
  }
}