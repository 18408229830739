.select-with-label {
    margin-bottom: 1rem;
    font-size: 14px;

    &.horizontal {
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 10px;
        display: flex;

        > div {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #FFFFFF;
        }
      }

    &__text {
        color: #999BBC;
        margin-bottom: 0.5rem;
        font-size: 14px;
        @media (max-width: 425px) { 
            font-size: 12px;
        }
    }

    .input-temporary {
        margin-top: -7px;
        input {
            height: 24px;
            background: transparent;
            border-radius: 0;
            font-family: "Montserrat", sans-serif !important;
            font-style: normal;
            font-weight: normal;
            font-size: 14px !important;
            line-height: 24px;
            border: 0 !important;
            width: 100%;
            border-bottom: 0.5px solid #5D5F7C !important;
            outline: none;
            text-align: left !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
    
            transition: border-color .25s ease;
        }

        .color-scheme-DARK {
            color: white;
        }

        .color-scheme-LIGHT {
            color: #0C0C23;
        }
    }
}