.RfqPage__wrapper {

    .DerivativeTable {
        padding-right: 0!important;
    }

    .page-title {
        position: relative;

        &.title-label-new {
            &:after {
                content: 'coming soon';
                width: 70px;
                height: 14px;
                text-transform: uppercase;
                color: #fff;
                font-size: 8px;
                position: absolute;
                background-color: #F6029C;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 20px;
                position: absolute;
                top: 4px;
                left: 17rem;

                @media (max-width: 425px) {
                    top: 1px;
                    left: 15.5rem;
                 }
            }
        }
    }
    

    .rfq-tab-section {
        display: flex;

        @media (max-width: 425px) {
            flex-direction: column;
            margin-bottom: 2rem;
         }
    
        .rfq-tabs {
            margin: 0 3rem 2rem 0;
        }
    }

    .rfq-table {
        thead {
            th {
                border-bottom: 1px solid #5D5F7C;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
                color: #999BBC;

                img {
                    cursor: pointer;
                    margin-left: 5px;
                }

                .rfq-table-content {
                    display: flex;
                    align-items: inherit;
                }

                &:nth-child(2), &:nth-child(3) {
                    width: 30%;

                    @media (max-width: 425px) {
                        white-space: nowrap;
                    }
                }

                &:nth-child(6) {
                    width: 20%;
                }

                &:last-child {
                  min-width: 20%;
                }

                &:nth-child(n + 3):not(:last-child) {
                    width: 11%;
                    white-space: nowrap;
                    background-color: rgba(20, 20, 60, 0.3);

                    @media (max-width: 1680px) {
                        width: 9%;
                    }
                }
            }
        }

        tbody {
            .rfq-item-wrapper {
                // &:hover {
                //     background-color: rgba(93, 95, 124, 0.3);
                //     cursor: pointer;
                // }
            }
            td {
                font-size: 12px;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                vertical-align: middle; 
        
                .item-box {
                    width: 100px;
                    padding: 0 0.3rem;
                    box-sizing: border-box;
                    border-radius: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 400;
                    white-space: nowrap;
                }

                .item-green {
                    background: rgba(27, 162, 89, 0.2)!important;
                    border: none!important;
                    color: #1BA259!important;

                    &:hover {
                        color: white!important;
                        background: #1BA259!important;
                    }
                }

                .item-red {
                    background: rgba(209, 19, 37, 0.2)!important;
                    border: none!important;
                    color: #D11325!important;

                    &:hover {
                        color: white!important;
                        background: #D11325!important;
                    }
                }

                .rfq-item-icon {
                    width: 24px;
                    height: 24px;
                    cursor: pointer;
                }

                .rfq-td-content {
                    margin: 0!important;
                    line-height: 18px;
                    white-space: nowrap;
                    display: inline-block;
                    .green {
                        color: #1BA259;
                    }

                    .red {
                        color: #D11325;
                    }

                    .bold {
                        font-weight: 700;
                    }
                }

                &:nth-child(n -1) {
                    padding-right: 40px;

                    @media (max-width: 1680px) {
                        padding-right: 50px;

                        @media (max-width: 1536px) {
                            padding-right: 20px;

                            @media (max-width: 1440px) {
                                padding-right: 10px;
                            }
                        }
                    }
                }

                &:last-child {
                    padding-right: 90px;

                    img {
                        width: 24px;
                        height: 24px;
                    }

                    @media (max-width: 1680px) {
                        padding-right: 50px;

                        @media (max-width: 1024px) {
                            padding-right: 30px;
                        }
                    }
                  }
                  
                &:nth-child(2) {
                    > div {
                        @media (max-width: 1440px) {
                            white-space: pre-line;

                            @media (max-width: 1024px) {
                                white-space: nowrap;
                            }
                        }
                    }
                }

                &:nth-child(n + 3):not(:last-child) {
                    background-color: rgba(20, 20, 60, 0.3);
                }
            }
        }
    }
}

.rfq-refresh-section {
    height: 4rem;
}
