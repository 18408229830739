.bar-chart-wrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  color: white;
  height: 100%;
  border-radius: 10px;
  padding: 24px 16px 8px 16px;

  @media screen and (max-width: 1024px) {
    width: 100%;
}

  .bar-chart-upper-section {
    width: 100%;
    margin-bottom: 15px;
  }

  .bar-chart-bottom-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }

  .bar-chart-title {
    font-size: 20px;
    line-height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-weight: 700;
  }

  .bar-chart-source {
    font-size: 12px;
    line-height: 16px;
    color: #5D5F7C;
    a {
      color: #5D5F7C;
      text-decoration: underline;
    }
  }
  
  .bar-chart-line {
    width: 100%;
    border: 1px solid #5D5F7C;
    margin-bottom: 20px;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
}
