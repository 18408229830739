
@import './../../Constants/Styles/colors.scss';

$minSearchBoxBlockHeight: 8rem;
$searchBoxBlockHeight: 49rem;

.ConstructorPage__wrapper {
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) { 
    flex-direction: column;
  }

  #opium-tabs-component-tab-derivative-active {
    line-height: 24px;
    font-weight: 700;
    font-size: 16px;
  }

  .ConstructorForm__wrapper {
    border: 1px solid #5D5F7C;
    padding: 1.5rem 1rem;
    border-radius: 10px;
    width: 343px;
  
    @media (max-width: 1024px) { 
      width: 430px;
    }

    @media (max-width: 768px) { 
      width: 100%;

      @media (max-width: 320px) { 
        padding: 5px;
      }
    }
    .selectors-wrapper__divided {
      display: flex;
      justify-content: space-between;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;

      &.full-width {
        .select-with-label {
          width: 100%;
        }
      }

      @media (max-width: 320px) { 
        flex-direction: column;
      }
      .select-with-label {
        width: calc((100% - 1rem) / 2);

        @media (max-width: 320px) {
          width: 100%;
         }
      }

      .recieve-block {

        .positions-address-wrapper {
          display: flex;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          margin-bottom: 1rem;

          .tokens {
            margin-left: 10px;
            font-weight: 400;
            font-size: 14px;
          }
          .red-tokens {
            color: #D11325;
          }
          .green-tokens {
            color: #1BA159;
          }
        }
      }

      .divided-block {
        width: 100%;
        display: flex;
        flex-direction: column;

        .labels-wrapper {
          display: flex;
          padding: 0;
          align-items: center;
          font-size: 18px;
          .label-item {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 4px 8px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            font-family: "Montserrat", sans-serif;
            cursor: pointer;

            position: static;
            width: 70px;
            height: 24px;
            left: 0px;
            top: 0px;
            color: #fff;
            background: #2C2C4A;
            border-radius: 100px;

            &.label-wide {
              width: 120px;
            }

            &.color-scheme-DARK {
              color: #fff;
            }
    
            &.color-scheme-LIGHT {
              color: #0C0C23;
            }

            .label-wrapper {
              display: flex;
              justify-content: center;

              &.label-right {
                margin-left: 5px;
              }

              img {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }

    .selectors-wrapper__row {

      .selector-label {
        display: flex;
        align-items: center;

        img {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          margin-right: 8px;
          display: flex;
        }
      }
      @media (max-width: 393px) { 
        flex-direction: column;
        width: 100%;

        .select-with-label {
          width: 100%;
        }
      }
    }

    .block-with-line {
      border-bottom: 1px solid #5D5F7C;
    }
  
    .ticker {

      &-title {
        color: #999BBC;
        margin-bottom: 1rem;
        font-size: 14px;
        @media (max-width: 425px) { 
          font-size: 13px;
        }
      }
      &-text {
        font-size: 14px;
        font-weight: 500;

        @media (max-width: 360px) { 
          font-size: 14px;
        }

        @media (max-width: 320px) { 
          font-size: 13px;
        }

        &.color-scheme-DARK {
          color: #fff;
        }

        &.color-scheme-LIGHT {
          color: #0C0C23;
        }
      }
    }
  
  
    select {
      background: transparent;
      color: #fff;
      padding: 5px;
      border: none;
      border-bottom: 1px solid #5D5F7C;
      border-radius: 0;
      outline: none;
      width: 100%;
    }
  
    option {
      background: #0C0C23;
      color: #fff;
    }
  
    input[type="number"] {
      background: transparent;
      border: none;
      border-bottom: 1px solid #5D5F7C;
      color: #fff;
      padding: 5px;
      width: 100%;
    }
  }

  .ConstructorPage__right {
    padding: 0 3rem;
    width: 100%;

    @media (max-width: 1440px) { 
      padding: 0 0 0 3rem;
    }

    @media (max-width: 1024px) { 
      min-width: 450px;
      padding: 0 3rem;
    }

    @media (max-width: 768px) { 
      margin-top: 3rem;
      width: 100%;
    }

    @media (max-width: 425px) { 
      padding: 0 0.5rem;
      min-width: 385px;

      @media (max-width: 393px) { 
        min-width: 355px;

        @media (max-width: 390px) { 
          min-width: 350px;

          @media (max-width: 375px) { 
            min-width: 310px;

            @media (max-width: 320px) { 
              min-width: 280px;
            }
          }
        }
      }
    }

    .ConstructorData__wrapper {
      width: 100%;
      position: relative;
      .constructor-data-prices {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 36px;
        margin-bottom: 36px;

        @media (max-width: 768px) { 
          justify-content: space-between;
        }

        .constructor-data-prices-item {
          border: 1px solid #5D5F7C;
          padding: 1.5rem 1rem;
          border-radius: 10px;
          height: 6rem;
          width: 45%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;

          @media (max-width: 1024px) { 
              justify-content: space-between;
              padding: 0.5rem 1rem;

              @media (max-width: 320px) { 
                height: 7rem;
              }
            }

          &-title {
            color: #999BBC;
            margin-bottom: 5px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            @media (max-width: 375px) { 
              min-height: 1.8rem;
              display: flex;
              align-items: flex-start;
            }
          }
          &-text {
            color: #fff;
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;

            @media (max-width: 360px) { 
              font-size: 16px;
              line-height: 18px;
            }

            &.color-scheme-DARK {
              color: #fff;
            }
  
            &.color-scheme-LIGHT {
              color: #0C0C23;
            }
          }
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-left: 3rem;

            @media (max-width: 375px) { 
              margin-left: 1rem;
            }
          }
        }
      }

      .constructor-payout-chart-tooltip {
        position: absolute;
        right: 0;
        top: 5px;
        z-index: 1000;
      }

      .derivative-hash {
        width: 45%;

        &.derivaive-hash-bottom {
          @media (max-width: 1024px) { 
            margin-bottom: 1rem;

            @media (max-width: 768px) { 
              margin-bottom: 0;

              @media (max-width: 425px) { 
                margin-bottom: 1rem;
              }
            }
          }
        }

        @media (max-width: 1440px) { 
          height: 80px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: inherit;

          @media (max-width: 1024px) { 
            width: 100%;
            display: block;
            @media (max-width: 768px) { 
              width: min-content;
              height: 80px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: inherit;

              @media (max-width: 425px) { 
                width: 100%;
                display: block;
              }
            }
          }
        }

        &__wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          @media (max-width: 1024px) { 
            flex-direction: column;

            @media (max-width: 768px) { 
              flex-direction: row;

              @media (max-width: 425px) { 
                flex-direction: column;
              }
            }
          }
        }
      
        &-title {
          margin-bottom: 0.5rem;
          color: #999BBC;
          font-size: 14px;

          @media (max-width: 425px) { 
            font-size: 13px;
          }
        }
  
        &-text {
          width: 24rem;
          color: #fff;
          font-size: 14px;
          background-color: rgba(93, 95, 124, 0.15);
          padding: 4px 8px;
          border-radius: 100px;
          margin-right: 5px;
          display: flex;
          justify-content: center;
  
          @media (max-width: 1536px) { 
            width: 19rem;

            @media (max-width: 1024px) { 
            width: 16rem;

              @media (max-width: 360px) { 
                font-size: 14px;

                @media (max-width: 320px) { 
                  font-size: 13px;
                  margin-right: 0;
                }
              }
            }
          }
  
          &.color-scheme-DARK {
            color: #fff;
          }
  
          &.color-scheme-LIGHT {
            color: #fff;
          }

          &.green-bg {
            background-color: #0D282A;
          }

          &.red-bg {
            background-color: #320C1F;
          }
        }
  
        &-content {
          display: flex;
          justify-content: flex-start;
          align-items: baseline;
          max-height: 2.5rem;
  
          img {
            cursor: pointer;
            width: 17px;
            margin-left: 8px;
          }
        }
  
        &-icon-wrap {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
        }
        &-link {
          cursor: pointer;
          display: flex;
          align-items: flex-start;
        }
      }
  
      .constructor-data-charts {
        &-title {
          border-bottom: 1px solid white;
          margin-bottom: 0 !important;
          display: flex;
          justify-content: space-between;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          width: 100%;
          color: white;

          img {
            margin-bottom: 4px;
            cursor: pointer;
          }
        }
        &-wrapper {
          width: 100%;
          padding: 0px;
          display: flex;
          justify-content: flex-start;
          left: 0;
          border: 1px solid #5D5F7C;
          padding: 1.5rem 1rem;
          border-radius: 10px;
          border-top-right-radius: 0;
          border-top-left-radius: 0;
          .color-scheme-DARK {
            color: white;
          }

          .color-scheme-LIGHT {
            color: #0C0C23;
          }
        }
        &-no-data {
          height: 256px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          
        }
      }

      .ConstructorPage__right {

        &-block {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .block-title {
            margin-bottom: 5px;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
    
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            width: 100%;

            &.color-scheme-DARK {
              color: #fff;
            }
  
            &.color-scheme-LIGHT {
              color: #0C0C23;
            }
          }
        }
      }

      .nav-tabs {
        .nav-link {
          margin-right: 1rem !important;
          padding: 0.5rem 1rem 0.5rem 0;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #999BBC;

          &.active {
            color: #fff;
          }
        }
      }
    }

    .mint-link-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 2rem;

      .mint-btn {
        width: 213px;
        height: 24px;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        display: flex;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;

        &::after {
          content: '';
          position: relative;
          width: 6px;
          height: 6px;
          top: -3px;
          background-image: url('../../Images/Icons/link-target-blank-white.svg');
          transition: background-image 0.15s linear;
        }

        &:hover {
          &::after {
            background-image: url('../../Images/Icons/link-target-blank-black.svg');
          }
        }
      }
    }
  }

  .constructor-block-title-DARK {
    width: auto;
    font-size: 16px;
    color: white;
    font-weight: 700;
    line-height: 24px;
    border-bottom: 1px solid white;
  }

  .constructor-block-title-LIGHT {
    width: auto;
    font-size: 16px;
    color: #0C0C23;
    font-weight: 700;
    line-height: 24px;
    border-bottom: 1px solid #0C0C23;
  }

  .constructor-form-bottom {
    margin-bottom: 40px;
  }

  .constructor-data-fee {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #5D5F7C;
    padding-bottom: 1.5rem;

    &-title {
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      color: #999BBC;
    }

    &-text {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
      display: flex;
      align-items: baseline;
      height: 100%;
      display: flex;
      align-items: center;

      .popover__disabled-button {
        display: flex!important;
      }
    }

    img {
      margin-left: 7px;
      cursor: pointer;
    }
  }

  .collateral-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: baseline;
    white-space: nowrap;

    @media (max-width: 425px) { 
      white-space: pre-line;
      @media (max-width: 320px) { 
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.oracles-price-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 260px;
}

.CustomChart {
  @media (max-width: 1024px) { 
    width: 400px !important;
  }

  @media (max-width: 320px) { 
    width: 320px !important;
  }
}

.ConstructorPage__search {
  width: 100%;
  position: relative;

  &-top {
    display: flex;
    position: relative;
    width: 100%;
    justify-content: space-between;

    @media (max-width: 425px) {
      flex-direction: column;
      margin-bottom: 1rem;
    }

    .popover__disabled-button {
      width: 70%;
    }

    .directives-back-link {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #FFFFFF;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      margin-bottom: 12px;

      img {
        margin-left: 1rem;
        cursor: pointer;
      }

      @media (max-width: 425px) { 
        padding-bottom: 15px;
      }
    }
  }

  .SearchBox__btn {
    border: none;
    padding: 5px;
  }

  @media (max-width: 768px) {
    padding:  0 3rem;
    width: 100%;

    @media (max-width: 425px) { 
      padding: 0 8px;
    }
  }

  &-title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 90px;
    color: white;
  }

  .opium-link {
    margin-top: 1rem;
    position: relative;
    margin-right: 3rem;
    
    a {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #fff;
      text-decoration: none;
    }
  }
}

.animate-search {
  animation: .4s linear 0s raise-search-box;
}

@keyframes raise-search-box {
  0% { 
    transform: translateY(calc(5% + #{$minSearchBoxBlockHeight}));
   }
  100% { 
    transform: translateY(0%);
    padding-top: 0;
   }
}

.address-wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  width: auto;
  padding: 5px 0 10px 0;

  .popover-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 1rem;

    color: #FFFFFF;
  }

  .popover-content {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .popover-field {
      display: flex;
      transition: border-color .25s ease;
      border-bottom: 0.5px solid #5D5F7C !important;

      input {
        height: 24px;
        background: transparent;
        border-radius: 0;
        border: 0 !important;
        width: 170px;
        outline: none;
        text-align: left !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        bottom: 6px;
        color: #999BBC;
    }

      .popover-image-circle {
        width: auto;
        height: 16px;
        margin-right: 4px;
        bottom: 0;
        border-radius: 16px;
        margin-top: 2px;
        object-fit: cover;
        cursor: default;
    }
    }

    .popover-images-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      a {
        display: flex;
      }
    }

    img {
      padding: 0 3px;
      cursor: pointer;
    }
  }
}

.explorer-error {
  max-width: 723px;
  @media (max-width: 425px) { 
    width: 400px;
  }
}
