.constructor-data-strategy {
        
    &-position {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding-bottom: 0.9rem;
      border-bottom: 1px solid #5D5F7C;
    }

    &-title {
      margin-top: 10px;
      
      .text-uppercase {
        text-transform: uppercase;
      }
    }

    .success {
      color: #2ECD94;
    }

    .danger {
      color: #F6029C;
    }

    &-content {
      width: 100%;
      padding: 1rem 0;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #999BBC;

      p {
        margin-bottom: 1rem;

        a.text-DARK {
          color: #fff;
        }
        a.text-LIGHT {
          color: #0C0C23;
        }
      }
    }
  }