.AuctionsPage__wrapper {

    .AuctionTable {

        @media (max-width: 1024px) {
            overflow: auto;
        }

        .auctions-table {
            width: 100%;

            @media (max-width: 1024px) {
                white-space: nowrap;
            }

            thead {
                th {

                    &:first-child {
                        width: 8%;
                    }

                    &:nth-child(2) {
                        width: 11%;
                    }

                    &:nth-child(3) {
                        width: 27%;

                        @media (max-width: 768px) {
                            width: 45%;
                        }
                    }

                    &:nth-child(4), &:nth-child(5), &:nth-child(6) {
                        width: 14%;

                        @media (max-width: 768px) {
                            width: 10%;
                        }
                    }
                }
            }

            tbody {

                tr {
                    &.row-green {
                        background-color: rgba(27, 162, 89, 0.1);
                        animation: flashing 1.4s linear infinite;

                        td {
                            border-top: 1.1px solid rgba(27, 162, 89, 0.7);
                            border-bottom: 0.5px solid rgba(27, 162, 89, 0.7);
                        }
                        
                    }

                    td {
                        white-space: nowrap;
                        font-size: 12px;
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                        vertical-align: middle;
    
                        .auctions-item-icon {
                            width: 24px;
                            height: 24px;
                            cursor: pointer;
                        }
    
                        &.auctions-item-bold {
                            text-decoration: none;
                            font-weight: 700;
                            color: white;
                        }
    
                        &.auctions-item-bid {
                            padding-right: 9%;
                            text-align: right;

                            @media (max-width: 1680px) {
                                padding-right: 8%;

                                @media (max-width: 1440px) {
                                    padding-right: 6%;

                                    @media (max-width: 1024px) {
                                        padding-right: 2%;
                                    }
                                }
                            }
                            
                            .item-btn-green {
                                position: relative;

                                &:after {
                                    content: '';
                                    position: absolute;
                                    width: 65px;
                                    padding: 0 0.3rem;
                                    border-radius: 100px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    font-weight: 400;
                                    white-space: nowrap;
                                    background: rgba(27, 162, 89, 0.2)!important;

                                    height: 21px;
                                    top: -2px;
                                    right: -17px;
                                }
                                
                              } 
                        }
    
                        &.text-green {
                            color: #1BA159;
                            font-weight: 700;
                        }
    
                        &.text-yellow {
                            color: #D6A72E;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }

    .actions-refresh-section {
        width: 100%;
        height: 4rem;

        .derivatives-tabs-anim-svg {
            right: 0;
        }
    }
}

@keyframes flashing {
    0% {
        background-color: rgba(27, 162, 89, 0.1);
    }
    50% {
        background-color: rgba(27, 162, 89, 0.2);
    }
    100% {
        background-color: rgba(27, 162, 89, 0.1);
    }
  }