$menuArticlesWidth: 200px;
$menuWidth: 223px;

.KnowledgeBase__wrapper {
    width: 100%;
    display: block;

    .KnowledgeBase__main-content {
        width: 100%;
        display: flex;
        position: relative;

        .KnowledgeBase {
            &__part-left {
                width: calc(100% - #{$menuArticlesWidth});
                display: flex;
                flex-direction: column;
                padding-right: 3rem;

                .KnowledgeBase__block {
                    &-title {
                        margin-bottom: 5px;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;
                        display: flex;
                        align-items: flex-end;
                        justify-content: flex-start;
                        width: 100%;

                        &.color-scheme-DARK {
                            color: #fff;
                          }
                  
                          &.color-scheme-LIGHT {
                            color: #fff;
                          }

                          &.bottom-line {
                            border-bottom: 1px solid #5D5F7C;
                            padding: 2rem 0 0.9rem 0;
                          }
                    }

                    &-content {
                        width: 100%;
                        padding: 1rem 0;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: #999BBC;

                        a.text-DARK {
                            color: #fff;
                            }
                            a.text-LIGHT {
                            color: #0C0C23;
                        }
                    }

                    &-charts-wrapper {
                        width: 100%;
                        padding: 0px;
                        display: flex;
                        justify-content: flex-start;
                        left: 0;
                        border-top: 1px solid #5D5F7C;
                        padding: 1.5rem 1rem;

                        .CustomChart {
                            width: 100% !important;
                        }

                        @media (max-width: 425px) {
                            padding: 0;
                        }
                    }
                }
    
                .page-text {
                    width: 100%;
                    display: block;

                    ul {
                        padding: 0;
                        li {
                            margin-left: 1.5rem;
                            position: relative;
                            margin-bottom: 0;

                            .page-text {
                                margin: 0;
                            }

                            &::before {
                                content: '•';
                                display: block;
                                position: absolute;
                                left: -15px;
                                top: -2px;
                                color: #999BBC;

                            }
                        }
                    }

                    a {
                        color: #ffffff;
                        text-decoration: none;
                    }
                }

                .text-indent {
                    margin-bottom: 3rem;
                }

                @media (max-width: 1200px) {
                    width: 100%;

                    @media (max-width: 768px) {
                        padding: 0 1rem;
                    }
                }

                .KnowledgeBase__content {
                    font-family: 'Montserrat', sans-serif;

                    .page-text-danger {
                        font-family: 'Montserrat', sans-serif;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 24px;
                        line-height: 32px;
                        color: #D11325;
                    }

                    .thin-text-danger {
                        display: inline-block;
                        color: #D11325;
                    }

                    .thin-text-success {
                        display: inline-block;
                        color: #1BA159;
                    }

                    @media (max-width: 360px) {
                        .page-title {
                            font-size: 18px;
                        }
                    }
                }
            }
    
            &__part-right {
                width: $menuArticlesWidth;

                .MenuList__block {
                    height: 20rem;
                    position: fixed;
                }
                @media (max-width: 1024px) {
                    display: none;
                }
            }
        }
    }

    .KnowledgeBase__burger-menu {
        position: fixed;
        top: 2rem;
        right: 2rem;
        display: none;
        z-index: 1001;

        img {
            display: block;
            cursor: pointer;
        }

        @media (max-width: 1024px) {
            display: block;

            @media (max-width: 768px) {
                z-index: 1000;

                @media (max-width: 360px) {
                    top: 2rem;
                    right: 1rem;
                }
            }
        } 
    }

    .KnowledgeBase__menu-wrapper {
        position: fixed;
        top: 0;
        right: 0;
        display: block;
        color: #ffffff;
        width: $menuWidth;
        background: #101034;
        height: 100%;
        z-index: 1002;
        top: 0;
        right: -100%;
        transition: all 1s linear;

        @media (max-width: 425px) {
            transition: all 0.5s linear;
        }

        &.active {
            right: 0;
        }

        .MenuList__block {
            .MenuList {
                padding: 4rem 0 2rem 2rem;
            }
        }

        img {
            cursor: pointer;
            top: 2rem;
            right: 2rem;
            display: block;
            position: relative;
            float: right;
        }

        .MenuAccordion__block {
            position: relative;
            display: flex;
            top: $menuWidth / 2;
            padding: 0 2rem;
        }
    }

    .KnowledgeBase-subtitle {
        padding-top: 1.5rem;
    }

    .KnowledgeBase-image {
        object-fit: cover;
        width: 100%;
        margin-bottom: 2rem;
    }

    .KnowledgeBase-note {
        font-style: italic;
    }

    .KnowledgeBase-table {
        color: #ffffff;
        margin-bottom: 2rem;

        tr {
            th {
                width: 25%;
                padding: 10px 15px;
                font-weight: 700;
                border-bottom: 0.1px solid #999BBC;
                font-size: 14px;
                &:last-child {
                    font-weight: normal;
                }
            }
        }
        .KnowledgeBase-item-wrapper {
            td {
                border-bottom: 0.1px solid #999BBC;
                width: 25%;
                padding: 10px 15px;
                &:first-child {
                    font-weight: bold;
                }

                img {
                    width: 100%;
                }
            }
        }
    }
   
    .KnowledgeBase-simple-table {
        border-top: 0.1px solid #999BBC;
        border-bottom: 0.1px solid #999BBC;
        .KnowledgeBase-item-wrapper {
            td {
                padding: 10px 15px;
                color: #999BBC;
                vertical-align: top;
                line-height: 20px;

                a {
                    color: #ffffff;
                    text-decoration: none;
                    padding: 10px 0;
                }
                img {
                    margin-top: 5px;
                    width: 100%;
                }
            }
        }
    }
}