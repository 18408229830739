@import '../../../Constants/Styles/colors.scss';

.covered-call {
  &-table {
    display: block;
    max-width: 100%;
    overflow-x: auto;
    line-height: 1.5;
  
    .title {
      font-size: 14px;
      font-weight: bold;
    }
  
    .maturity {
      font-size: 12px;
    }

    .apr {
      text-align: right;
    }

    .risk {
      padding-left: 40px;
    }
  }

  &-name {
    width: 17% !important;
  }

  &-components {
    width: 30% !important;
  }

  &-apr {
    width: 5% !important;
    text-align: right;
  }

  &-risk {
    width: 40% !important;
    padding-left: 40px !important;
  }

  &-profit-loss {
    width: 20% !important;
  }

  &-popup {
    .popup-wrapper {
      display: flex;
      flex-direction: row;
      align-items: stretch;

      @media screen and (max-width: 1024px) {
        flex-direction: column;
      }
    }

    .info {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-right: 50px;

      @media screen and (max-width: 1024px) {
        margin-right: 0;
        margin-bottom: 2rem;
      }

      ul {
        li {
          display: grid;
          grid-template-columns: repeat(2, auto);
          grid-gap: 30px;
          padding: 10px 0;

          .info-label {
            font-size: 14px;
            line-height: 1.15;
            margin-right: 40px;
          }

          .info-value {
            font-size: 14px;
            line-height: 1.15;
            font-weight: 800;
            text-align: right;
            white-space: nowrap;

            a {
              font-weight: 800;
              color: white;
            }
          }
        }
      }
    }

    .charts {
      .tab-wrapper {
        height: 300px;

        @media screen and (min-width: 1200px) {
          width: 650px;
        }

        @media screen and (min-width: 992px) and (max-width: 1199px) {
          width: 450px;
        }
      }

      .nav-tabs {
        margin-bottom: 20px !important;
      }

      .error-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }

    .staking-risk-disclosure-text {
      font-size: 12px;
      line-height: 16px;

      b {
        font-weight: bold;
      }
    }
  }
}

.token-analytics.tab-wrapper {
  .tooltip-wrapper {
    padding: 0 2rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.token-analytics-chart-tooltip {
  font-size: 13px;
  font-weight: bold;
  line-height: 1.15;

  .chart-tooltip-field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    &.token-price {
      color: $pink;
    }
  
    &.lp-price {
      color: #197CD8;
    }

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  .chart-tooltip-label {
    margin-right: 10px; // TODO
  }
}

.pl-1inch-footnote {
  padding: 0 2rem;
}
