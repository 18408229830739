.prices-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.prices-charts-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  width: 90%;
  grid-gap: 100px;

  .prices-chart {
    height: 55vh !important;
  }

  .prices-chart-item-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .prices-chart-upper-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .prices-chart-title {
    font-size: 20px;
    line-height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-weight: 700;
    color: white;
  }

  .prices-chart-source {
    font-size: 12px;
    margin-top: 10px;
    color: #5D5F7C;
    a {
      color: #5D5F7C;
      text-decoration: underline;
    }
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-gap: 70px;
    .prices-chart-title {
      font-size: 16px;
      color: white;
      margin-bottom: 16px;
    }
    .prices-chart {
      height: 70vh !important;
    }
  }
}