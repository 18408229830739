@import '../../../Constants/Styles/colors.scss';

.active-pools {
  &-table {
    display: block;
    line-height: 1.15;
    // min-width: 900px;
    max-width: 100%;
    overflow-x: auto;

    thead {
      tr {
        th {
          padding: 15px 20px !important;
        }
      }
    }

    tbody {
      td {
        vertical-align: middle;
        padding: 15px 20px !important;
      }
    }
  
    .active-pool-name {
      font-size: 14px;
      font-weight: bold;
      white-space: nowrap;
    }
  
    .period {
      font-size: 12px;
    }
  
    .strike-price {
      text-align: end;
    }

    .ticker {
      white-space: nowrap;
    }
  
    .underlying-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
  
    .tooltip-wrapper {
      margin-left: 0.5rem;
      margin-top: 0.2rem;
      cursor: pointer;
    }
  
    .chain-logo {
      max-width: 30px;
      max-height: 30px;
      object-fit: contain;
    }

    @media screen and (max-width: 1100px) {
      width: unset;
    }
  }

  &-popup {
    .popup-wrapper {
      display: flex;
      flex-direction: row;
      align-items: stretch;

      @media screen and (max-width: 1024px) {
        flex-direction: column;
      }
    }

    .info {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      min-width: 275px;
      margin-right: 30px;

      @media screen and (max-width: 1024px) {
        margin-right: 0;
        margin-bottom: 2rem;
      }

      ul {
        width: 100%;

        li {
          display: grid;
          grid-template-columns: repeat(2, auto);
          grid-gap: 30px;
          padding: 10px 0;

          .info-label {
            font-size: 14px;
            line-height: 1.15;
            margin-right: 20px;
          }

          .info-value {
            font-size: 14px;
            line-height: 1.15;
            font-weight: 800;
            white-space: nowrap;
            text-align: end;

            .opium-link {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;

              a {
                font-size: 14px;
                font-weight: 800;
                color: white;
              }
            }
          }
        }

        @media screen and (max-width: 1024px) {
          width: unset;          
        }
      }
    }

    .chart {
      &-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &-title {
        font-size: 18px;
        line-height: 1.15;
        font-weight: bold;
        align-self: flex-start;
        margin-left: 5.5rem;
      }

      &-loading-wrapper,
      &-loading-error {
        min-width: 500px;
        min-height: 250px;
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

