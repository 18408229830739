@import './constants.scss';
@import './colors.scss';

:root {
	--app-height: 100vh;
}

html {
	font-size: 14px;
	height: var(--app-height);
}

body {
	//background: radial-gradient(72.74% 182.65% at 85.89% 66.02%, #111132 2.27%, #0A0A1E 98.05%);

	-moz-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;

	* {
		font-family: 'Montserrat', sans-serif;
		box-sizing: border-box;
		.text-padding-on-mac-os-chrome {
			padding-top: 3px;
		}
	}

	.loading {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

button,
input {
	outline: none !important;
	outline-offset: none !important;
	box-shadow: none !important;

	&:focus,
	&:active {
		outline: none !important;
		outline-offset: none !important;
		box-shadow: none !important;
	}
}

#root {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	background-color: #0A0A1E;
				// background-image: radial-gradient(72.74% 182.65% at 85.89% 66.02%, #111132 2.27%, #0A0A1E 98.05%);

	.app {
    min-height: inherit;
		.blurred {
			filter: blur(4px);
		}
		&.color-scheme {
			&-DARK {
				background-color: #0A0A1E;
			}

			&-LIGHT {
				background-color: white;
			}
		}
	}


	.main {
		flex-grow: 1;
		display: flex;
		flex-direction: row!important;
		height: 100%;

		& > .page-container {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			width: calc(100% - #{$sideBarWidth});
			margin-left: $sideBarWidth;
			position: relative;
			overflow: auto;
			min-height: 0;
			& > .main-content {
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;

				height: 100%;
				width: 100%;
			}
		}
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.page-container {
	width: 100%;
	margin: 0 auto;
	padding: 0 32px;
}

.buttons-wrap {
	text-align: center;
	margin: 0;
	width: 100%;
	.btn:not(:last-child) {
		margin-right: indent(2);
	}
	.create-derivative-popup-cancel-button {
		width: indent(17);
	}
}

.visually-hidden {
	position: absolute;
	clip: rect(0 0 0 0);
	width: 1px;
	height: 1px;
	margin: -1px;
}

.anchor {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(100% + 80px);
	pointer-events: none;
	z-index: 9999;
}

.custom-table {
	font-family: 'Montserrat', sans-serif;

	.table-body {
		.table-row {
			.table-cell {
				.text-block-content {
					height: 100%;
					display: flex;
					align-items: flex-start;
					flex-direction: column;
					justify-content: space-evenly;
					overflow: hidden;
					white-space: normal;
					&.unipoolinfo {
						width: 100%;
						justify-content: center;
						span {
							margin: 0;
						}
					}
					img {
						margin-bottom: -8px;
					}
					div {
						span {
							margin: 0 5px;
						}
						.phase-slash {
							margin: 0 -3px 0 2px;
						}
					}
					.child {
						span {
							margin: 0;
						}
						.line {
							width: 100%;
							span {
								margin-left: 4px;
							}
						}
						display: inline-block;
					}
				}
				.text-block {
					height: 100%;
					padding: 2rem 0;
					background-color: transparent;
					border: 1px solid transparent;
					display: flex;
					align-items: center;
					justify-content: space-evenly;
					flex-direction: column;
				}
				button {
					color: rgb(75, 75, 78);
					background-color: rgb(255, 255, 255);
					border-color: rgb(25, 124, 216);
					border-style: solid;
					margin: 1rem;
					width: 100%;
					white-space: pre-wrap;
					word-break: normal;
				}
			}
		}
	}
}

.page-title {
	font-family: Montserrat;
	font-size: 26px;
	line-height: 40px;
	font-weight: bold;
	color: white;
	margin-bottom: 1rem;

	@media screen and (max-width: 500px) {
		font-size: 24px;
		line-height: 36px;
		margin-bottom: 0.5rem;
	}
}

.page-subtitle {
	font-family: Montserrat;
	font-size: 24px;
	line-height: 36px;
	font-weight: bold;
	color: white;
	margin-bottom: 1rem;

	@media screen and (max-width: 500px) {
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 0.5rem;
	}
}

.page-text {
	font-family: Montserrat;
	font-size: 16px;
	line-height: 24px;
	color: #999BBC;
	margin-bottom: 1.5rem;

	a {
		color: #999BBC;
		text-decoration: underline;
	}

	@media screen and (max-width: 500px) {
		font-size: 12px;
		line-height: 15px;
		margin-bottom: 0.5rem;
	}
}

.page-wrapper {
	padding-right: 50px;

	@media screen and (max-width: 1200px) {
		padding-right: 0;
	}
}

.loader-wrapper {
	display: flex;
	justify-content: center;
	height: 100vh;
}

@media (max-width: breakpoint('laptopS')) {
	.main {
		.page-container {
			padding: 0 32px;
		}
  }

  .custom-table .table-head-cell {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }
}

@media (max-width: 1200px) {
	#root {
		.main {
			& > .page-container {
				width: calc(100% - #{$sideBarTabletWidth});
				margin-left: $sideBarTabletWidth;
			}
		}
	}
}

@media (max-width: 768px), (max-width: 812px) and (max-height: 425px) and (orientation: landscape) {
	#root {
		.main {
			& > .page-container {
				width: 100%;
				margin-left: 0;
				padding: 0 16px;
			}
		}
	}

}

@media screen and (max-width: 480px) {
	#root {
		.main {
			padding-top: 16px;
			& > .page-container {
				margin-left: 0;
			}
		}
	}

	.custom-table {
		.table-body {
			.table-row {
				flex-direction: column;
				.table-cell {
					width: 100%;
					div {
						display: block;
						align-items: center;
						flex-wrap: wrap;
					}
					.table-cell {
						&__btn {
							display: flex;
						}
					}
					.tab-image-wrapper {
						display: flex;
						align-items: center;
						justify-content: center;
					}
					.text-block {
						padding: 0.5rem 0;
					}
					button {
						margin-bottom: 5px;
					}
				}
			}
		}
	}
	.page-container {
		padding: 0;
		margin-left: 0;
	}
	// #root {
	// 	padding-top: 80px;
		// .main {
		// 	padding-top: 80px;
		// }
	// }
	.anchor {
		top: -80px;
	}
}

.turbo-intro-content {
	line-height: 20px;
}

table.transparent-table {
  color: #999BBC;
  thead {
		width: 100%;
    th {
			width: 25%;
      padding: 10px 15px;
      font-weight: 700;
      border-bottom: 1px solid #5D5F7C !important;
      font-size: 12px;
	  vertical-align: middle;
    }
  }

  tbody {
    border-radius: 10px;
    padding: 0px 10px;

    td {
      padding: 15px;
      border-bottom: 0.5px solid #34344D;
	  color: white;
    }
  }

	&.clickable-rows {
		tbody {
      tr {
        &:hover {
          background: rgba(93, 95, 124, 0.3);
          cursor: pointer;
        }
      }
    }
	}
}

.error {
	color: $red3;
}

.message {
	color: white;
}

.nowrap {
	white-space: nowrap;
}

.text-bold {
	font-weight: bold;
}

input[type="text"]::-webkit-input-placeholder {
    color: #5D5F7C;
}
