.ConstructorPage__left {

    @media (max-width: 768px) { 
      padding: 0 3rem;
    }
  
    @media (max-width: 425px) { 
      padding: 0 0.5rem;
    }
  
      .ConstructorForm__footer {
        width: 100%;
  
        .constructor-data-recieve {

          .constructor-bid-ask {
            padding: 1.5rem 1rem;
            display: flex;
            justify-content: space-between;

            @media (max-width: 393px) { 
              padding: 1.5rem 0;
            }

            .bid-ask-items {
              display: flex;
              justify-content: space-between;
              flex-direction: column;
              min-width: 50%;
    
              .bid-ask-titles {
                display: flex;
                width: 100%;
    
                .bid-ask-item-title {
                  width: 50%;
    
                  &:last-child {
                    margin-left: 1rem;
    
                    @media (max-width: 1024px) { 
                      margin-left: 2.5rem;
    
                      @media (max-width: 768px) { 
                        justify-content: center;
    
                        @media (max-width: 425px) { 
                          margin-left: 2rem;
                          justify-content: flex-start;
                        }
                      }
                    }
                  }
                }
              }
    
              .bid-ask-content {
                display: flex;
                justify-content: space-between;
    
                .bid-ask-expired {
                  display: flex;
                  justify-content: center;
                  width: 100%;
                  font-size: 12px;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    color: #FFFFFF;
                }
              }
    
              @media (max-width: 1024px) { 
                width: 50%;
              }
            }

            .bid-ask-item {
              display: flex;
              flex-direction: column;

              &-title {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
                display: flex;
                align-items: flex-end;
                color: #999BBC;
                margin-bottom: 0.6rem;

                img {
                  margin-left: 5px;
                  cursor: pointer;
                }
              }
              &-size {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                display: flex;
                align-items: center;
                color: #FFFFFF;

                .item-btn {
                  width: 75px;
                  padding: 0 0.3rem;
                  border-radius: 100px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-weight: 400;
                  white-space: nowrap;
                  border: none!important;
  
                  &.item-green {
                    background: rgba(27, 162, 89, 0.2)!important;
                    color: #1BA259!important;
  
                    &:hover {
                        color: white!important;
                        background: #1BA259!important;
                    }
                  }

                  &.item-red {
                    background: rgba(209, 19, 37, 0.2)!important;
                    color: #D11325!important;

                    &:hover {
                        color: white!important;
                        background: #D11325!important;
                    }
                  }
                }
              }
            }

            @media (max-width: 320px) { 
              padding: 10px 0;
            }
          }
        }
  
      }
  
    }
  
    #opium-tabs-component-tab-receive-active {
      line-height: 24px;
      font-weight: 700;
      font-size: 16px;
    }
  
    #opium-tabs-component-tab-put-to-amm {
      line-height: 24px;
      font-weight: 700;
      font-size: 16px;
    }
    
    .opiumBtn {
  
        .button-label {
          display:flex;
          white-space: pre-wrap;
          justify-content: center;
      
          .total-content {
            font-weight: 400;
          }
        }
  
        @media (max-width: 360px) { 
          font-size: 11px !important;
        }
    }
  