.footer-wrapper {
  height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  background-color: #0A0A1E;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  padding: 60px 0;

  @media (max-width: 767px) {
    height: auto;
    padding: 10px;
    width: 100%;
  font-size: 14x;
  line-height: 18px;
  }
}