.oracles-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .oracles-top-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: white;
    margin-bottom: 42px;

    .oracles-top-prices-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: nowrap;
      width: 100%;

      @media screen and (max-width: 425px) {
        justify-content: center;
        flex-wrap: wrap;

        .oracles-top-prices-item {
          width: 150px;
        }
      }
    }
  }

  .oracle-tabs-section {
    margin-bottom: 30px;
  }

  .oracles-description {
    a {
      color: white;
      text-decoration: underline;
    }
  }

  .oracles-loading-wrapper {
    margin: 50px 0;
  }
  
  @media (max-width: 767px) {
    display: block;

    .oracle-item-header {
      width: fit-content;
    }
    .oracle-item-wrapper  {
      width: fit-content;
    }
    .oracle-item {
      width: 15rem;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
  }
}

.oracles-table {
  width: 100%;
  overflow: auto;
  table-layout: fixed;
  white-space: nowrap;


  th {
    max-width: 25%;
    color: #999BBC;
    font-size: 12px !important;
    &:first-child, &:last-child {
      max-width: 10%;
      @media (max-width: 1024px) {
        max-width: 8% !important;
      }
    }

    &:nth-child(2) {
      @media (max-width: 1024px) {
        max-width: 19% !important;
      }
    }

    &:nth-child(3) {
      max-width: 18% !important;
      padding-right: 100px !important;
      text-align: center !important;

      @media (max-width: 1024px) {
        padding-right: 50px !important;
      }
    }

    &:nth-child(4) {
      @media (max-width: 1024px) {
        max-width: 14% !important;
      }
    }

    &:nth-child(5) {
      width: 350px;

      @media (max-width: 1536px) {
        width: 300px;

        @media (max-width: 1024px) {
          width: 25%;
        }
      }
    }

  }

  td {
    font-size: 12px !important;
    &:nth-child(3) {
      max-width: 18% !important;
      text-align: right !important;
      padding-right: 120px;

      @media (max-width: 1680px) {
        padding-right: 100px !important;

        @media (max-width: 1440px) {
          padding-right: 80px !important;

          @media (max-width: 1024px) {
            padding-right: 50px !important;
          }
        }
      }
     
    }

    text-align: left;
    vertical-align: middle;

    .oracle-item-icon {
      height: 24px;
      cursor: pointer;
    }

    .oracle-icon-circle {
      border-radius: 50%;
    }
  }

  @media (max-width: 768px) {
    white-space: pre-wrap;

    @media (max-width: 425px) {
      white-space: nowrap;
      table-layout: auto;
      overflow-x: auto;
      width: 100%;
    }
  }
}

.oracles-table-wrapper {
  width: 100%;
  overflow: auto;

  .no-data-table {
    font-family: Montserrat;
    font-size: 16px;
    line-height: 24px;
    color: #F6029C;
    margin-bottom: 1.5rem;
  }
}


.slider-oracles-top-prices-item {
  border: 0.5px solid #34344D;
  border-radius: 10px;
  display: flex!important;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 2rem;
  justify-content: center;
  width: 100%;
  margin: 15px 10px;
  max-width: calc(100% - 20px);

  @media screen and (max-width: 1680px) {
    margin: 15px 5px;
    max-width: calc(100% - 10px);
  }

  .slider-oracles-top-prices-item-title {
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
      border-radius: 10px;
      margin-right: 6px;
    }
  }

  .slider-oracles-top-prices-item-value {
    font-size: 14px;
    font-weight: 500;
  }

}

.slick-prev {
  left: -5px!important;
  @media screen and (max-width: 1680px) {
    left: 10px!important;
  }
}

.slick-next {
  right: -15px!important;
}

.slick-slider {
  display: flex;
  justify-content: center;
}

.slick-list {
  width: 98.4%!important;
  margin: 0 0 0 5px!important;

  @media screen and (max-width: 1680px) {
    width: 95.4%!important;
    margin: 0 0 0 20px!important;
  }

  @media screen and (max-width: 1024px) {
    width: 100%!important;
    margin: 0!important;
  }
}

.oracles-filters-buttons {
  width: 100%;
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 425px) {
    margin-top: 2rem;
    justify-content: space-between;
  }

  .oracles-button {
    display: flex;
    flex-direction: row;
    margin-right: 20px;
    margin-top: 0.7rem;

    @media screen and (max-width: 375px) {
      margin: 0;
    }

    &:hover {
      .label {
        color: #999BBC;
      }

      .opiumBtn {
        svg {
          line {
            stroke: #999BBC;
          }
        }
      }
    }

    .label {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      color: #FFFFFF;
      margin-right: 10px;
      text-transform: capitalize;

      @media screen and (max-width: 425px) {
        font-size: 12px;
        line-height: 12px;
      }
    }

    .opiumBtn {
      border: none;
      padding: 0;

      .oracles-remove-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 16px;

        position: relative;
        width: fit-content;
        height: 26px;

        background: #2C2C4A;
        border-radius: 100px;
        transition: all 0.15s linear;
      }

      svg {
        width: 9px;
        height: auto;
        margin: 0;
      }
    }
  }
}

.oracles-filters-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 3rem;
  @media screen and (max-width: 425px) {
    flex-wrap: wrap;
  }

  .filter-wrapper {
    max-width: 160px;
    margin-right: 3rem;
    width: 100%;

    @media screen and (max-width: 425px) {
      max-width: 45%;
      margin: 0 2rem 2rem 0;

      @media screen and (max-width: 320px) {
        max-width: 90%;
      }

      &:nth-child(2) {
        margin: 0 0 2rem 0;
      }
    }
  }
}
