.header {
  width: 100%;
  height: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 2rem;
  position: fixed;
  z-index: 1000;
  .header-desktop-logo {
    height: 50%;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}
