.line-chart-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: white;
  height: 100%;
  // border: 1px solid rgba(93, 95, 124, 0.5);
  border-radius: 10px;
  padding: 24px 16px 8px 16px;

  .line-chart-upper-section {
    width: 100%;
    margin-bottom: 15px;
  }


  .line-chart-title {
    font-size: 18px;
    line-height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-weight: 700;
  }

  .line-chart-source {
    margin-top: 10px;
    font-size: 12px;
    color: #5D5F7C;
    a {
      color: #5D5F7C;
      text-decoration: underline;
    }
  }
  
  .line-chart-line {
    width: 100%;
    border: 1px solid #5D5F7C;
    margin-bottom: 20px;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
}