.bid-ask-popup-wrapper  {
    .bid-ask-title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        position: absolute;
        top: -43.5px;
        left: 36px;

        span.green {
            color: #1BA159;
        }

        span.red {
            color: #D11325;
        }
    }
    .bid-ask-content {
        margin-bottom: 0.8rem;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;

        & > p {
            margin-bottom: 0 !important;
        }

        .tooltip-note {
            font-size: 10px;
            color: #999BBC;
            padding-top: 10px;
            line-height: 14px;
        }

        .tooltip-total {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-weight: 700;
            margin-top: 20px;
            font-size: 14px;
            .green {
                color: #1BA159;
            }

            .red {
                color: #D11325 !important;
            }
        }


        .tooltip-bold {
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
            width: 90px;
            height: 24px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            position: relative;
            display: flex;
            align-items: center;
            padding: 0 8px;

            &.green-box {
                background-color: #1BA1594D;
            }

            &.red-box {
                background-color: #D1132580;
            }

            &.tooltip-indent {
                margin-top: 1.2rem;
            }
        }

        .bold {
            font-weight: 700;
        }

        .tooltip-red-border {
            border-top: 0.5px solid #D11325;
            border-bottom: 0.5px solid #D11325;
        }

        .tooltip-green-border {
            border-top: 0.5px solid #1BA159;
            border-bottom: 0.5px solid #1BA159;
        }

        .tooltip-green-border-bottom {
            border-bottom: 0.5px solid #1BA159;
        }

        .tooltip-red-border-bottom {
            border-bottom: 0.5px solid #D11325;
        }

        // p {
        //     .green {
        //         color: #38C97B;
        //     }
    
        //     .red {
        //         color: #FF4758;
        //     }
        //     .tooltip-note {
        //         font-size: 12px;
        //         color: #999BBC;
        //     }
        //     margin: 0 !important;
        //     &:first-child {
        //         // padding-top: 0.7rem;
        //         // border-top: 0.1px solid rgba(93, 95, 124, 0.3);
        //         font-weight: 700;
        //     }
        // }
    }
    .actions-button {
        display: flex;
        justify-content: space-between;
    }
}

.bid-ask-popup {
    .modal-header {
        margin-bottom: 1.6rem!important;

        .modal-title {
            font-size: 14px;
        }
    }
}
