.volumes {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-charts-wrapper {
    padding-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .defi-volumes-loading {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 1024px) {
        width: unset;
        margin-top: 20px;
      }
    }

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }

  & .active-pools {
    &-loading-wrapper {
      margin: 50px 0;
    }
  }
}

.volumes-chart-tooltip {
  font-size: 13px;
  font-weight: bold;
  line-height: 1.15;

  .volumes-chart-tooltip-date {
    color: #197CD8;
    margin-bottom: 1rem;
  }

  ul {
    color: #197CD8;
    margin-bottom: 1rem;
  }

  .volumes-chart-tooltip-total {
    color: #197CD8;
  }

  .volumes-chart-tooltip-projected {
    color: #999BBC;
    align-items: flex-end;

    .tooltip-label {
      font-size: 12px;      
    }
  }

  .tooltip-field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.view-more-on-explorer-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 60px;
}
