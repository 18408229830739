.animate-fade-out {
  animation: .3s linear 0s fade;
}
@keyframes fade {
  from {
     opacity: 0;
  }
  to {
     opacity: 1;
  }
}
